/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { RadioButton, RadioLabel } from '../styles';

const CheckButton = props => {
  return (
    <>
      <RadioButton type="radio" {...props} />
      <RadioLabel htmlFor={props.id}>
        <span />
      </RadioLabel>
    </>
  );
};

export default CheckButton;

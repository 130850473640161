export const BAIRROS_LISTA = [
  {
    nome: 'NÃO SEI INFORMAR',
    taxa: 0,
  },
];

export const DELIVERY_OPTIONS = [
  {
    id: 'entrega',
    nome: 'Entrega',
    tempo: '(50-80mim)',
    adicional: '(sujeito a disponibilidade e taxas)',
    isBairro: true,
    taxa: null,
    value: 0,
  },
  {
    id: 'retirar',
    nome: 'Retirada no local',
    tempo: null,
    isBairro: false,
    adicional: '(sujeito a disponibilidade)',
    taxa: null,
    value: 0,
  },
];

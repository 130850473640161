/* eslint-disable no-unused-vars */
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PageFound, Loading } from 'components';
import { CarrinhoProvider } from 'contexts/carrinho-context';
import 'index.css';
import { BUSCA_ROUTER } from './pages/Busca/routes';

const Busca = lazy(() => import('pages/Busca/Busca'));
const Home = lazy(() => import('pages/Home/Home'));
const Cardapio = lazy(() => import('pages/Cardapio/Cardapio'));
const Pedidos = lazy(() => import('pages/Pedidos/Pedidos'));
const Sobre = lazy(() => import('pages/Sobre/Sobre'));
const PedidosDetalhes = lazy(() => import('pages/Pedidos/pages/Detalhes'));
const Carrinho = lazy(() => import('pages/Pedidos/pages/Carrinho'));
const Footer = lazy(() => import('components/Footer/Footer'));

function App() {
  useEffect(() => {
    if (window.location.hostname === process.env.REACT_APP_SUB_DOMINIO_PEDIDO) {
      window.location.replace(process.env.REACT_APP_URL_PEDIDO);
    }
  }, []);
  return (
    <Router>
      <Suspense fallback={<Loading style={{ height: '100vh' }} />}>
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/cardapio" component={Cardapio} />
            <Route path="/pedido">
              <CarrinhoProvider>
                <Switch>
                  <Route exact path="/pedido" component={Pedidos} />
                  <Route
                    exact
                    path={['/pedido/detalhes/:codigo', '/pedido/detalhes/:codigo/edit/:itemId']}
                    component={PedidosDetalhes}
                  />
                  <Route exact path="/pedido/carrinho" component={Carrinho} />
                  <Route exact path="/pedido/sobre" component={Sobre} />
                  <Route exact path={BUSCA_ROUTER} component={Busca} />
                </Switch>
              </CarrinhoProvider>
            </Route>
            <Route component={PageFound} />
          </Switch>
        </main>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;

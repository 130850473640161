import styled from 'styled-components';
import { COLORS } from 'styles';

export const FooterContainer = styled.footer`
  background: ${COLORS.dark};
  padding-top: 30px;
`;

export const Texto = styled.p`
  margin: 0;
  padding-top: 20px;
  color: ${COLORS.primary};
  font-family: 'Oswald', sans-serif;
  font-size: 0.6rem;
  letter-spacing: 0.5px;
`;

export const TextoDisplay = styled.p`
  margin: 0;
  padding-top: 5px;
  color: ${COLORS.primary};
  font-family: 'Oswald', sans-serif;
  font-size: ${props => props.fontSize || '0.9rem'};
  letter-spacing: 0.5px;
`;

export const Link = styled.a`
  & p {
    color: ${COLORS.primary};
    font-family: 'Oswald', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0.9px;
  }
`;

export const Logo = styled.img`
  height: 5vh;
  text-align: center;
`;

export const IconSocial = styled.img`
  height: 30px;
  text-align: center;
`;

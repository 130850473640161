import styled from 'styled-components';
import { COLORS } from 'styles';

export const CardpioTexto = styled.p`
  text-align: center;
  display: inline-block;
  margin: 0;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.texto};
  font-size: 1rem;
  letter-spacing: 0.9px;
  opacity: 0.9;
`;

import styled from 'styled-components';

export const COLORS = {
  primary: '#FCDEC2',
  secondary: '#582200',
  accent: '#FA6F00',
  success: '#2ecc71',
  red: '#d63031',
  warning: '#eccc68',
  texto: '#413221',
  light: '#bdc3c7',
  dark: '#3D362F',
};

export const TitleSection = styled.h1`
  color: ${COLORS.texto};
  margin: 0;
  font-size: calc(1rem + 0.5vw);
  font-family: 'Oswald';
  letter-spacing: 0.5px;
  /* box-shadow: 4px 2px 0px #fff; */
`;

export const ItemTitle = styled.p`
  color: ${COLORS.texto};
  font-size: ${props => props.fontSize || '1.1rem'};
  font-weight: ${props => props.weight || 500};
  opacity: ${props => props.opacity || 1};
  font-family: 'Oswald';
`;

export const ItemDescription = styled.p`
  color: ${props => props.color || COLORS.texto};
  font-size: ${props => props.fontSize || '0.9rem'};
  opacity: ${props => props.opacity || 0.8};
  font-family: 'Oswald';
`;

export const ItemValor = styled.p`
  display: inline-block;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.texto};
  font-size: ${props => props.fontSize || '1.1rem'};
  letter-spacing: 0.5px;
  font-weight: bold;
  opacity: 0.9;
`;

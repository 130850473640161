import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { CardpioTexto } from './styles';

const HeadTabela = ({ items }) => {
  const [item] = items;
  if (!item.tipo) return null;
  const { tabela } = item;
  return (
    <Row className="flex-row justify-content-between align-items-start">
      <Col xs={6} md={6} sm={6} />
      {Object.values(tabela).map(({ quantidade, unidade }) => (
        <Col key={quantidade} className="text-right" xs={3} md={3} sm={3}>
          <CardpioTexto className="text-center">
            {quantidade} ({unidade})
          </CardpioTexto>
        </Col>
      ))}
    </Row>
  );
};

HeadTabela.propTypes = {
  items: PropTypes.instanceOf(Object),
};

export default HeadTabela;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ImageCustom } from './style';
import 'react-lazy-load-image-component/src/effects/blur.css';

function ImageProgressive({ src, ...restProps }) {
  const onError = e => {
    e.target.onerror = null;
    e.target.src = 'assets/no_image.jpg';
  };
  if (!src) return null;
  return <ImageCustom src={src} effect="blur" {...restProps} onError={onError} />;
}

ImageProgressive.propTypes = {
  src: PropTypes.string,
};

export default ImageProgressive;

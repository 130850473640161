import React from 'react';
import logo from 'assets/logo.svg';
import iconInstagram from 'assets/instagram.svg';
import iconWhats from 'assets/whatsapp.svg';
import iconFacebook from 'assets/facebook.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FooterContainer, Texto, Logo, IconSocial, TextoDisplay } from './styles';

const Footer = () => {
  const location = useLocation();

  if (!location || !['/', '/cardapio', '/pedido'].find(it => it === location.pathname)) return null;
  return (
    <FooterContainer className="d-flex flex-column h-100">
      <Container className="justify-content-center align-items-center">
        <Row className="border-bottom border-warning mb-3">
          <Col xs={12} lg={{ offset: 3, span: 6 }} className="text-center text-white pb-4">
            <TextoDisplay fontSize="1.5em" className="text-white font-weight-bold">
              Aviso sobre pedidos
            </TextoDisplay>
            <TextoDisplay className="text-white font-weight-light">
              Ao fazer o pedido você será redirecionado para o <strong>Whatsapp</strong> no celular
              e no computador você deve se conectar com o Whatsapp Web e finalizar seu pedido seu
              pedido por lá!
            </TextoDisplay>
          </Col>
        </Row>
        <Row className="justify-content-center pb-4">
          <Col xs={2} lg={1} className="text-center">
            <a
              href="https://www.facebook.com/saborearresto"
              target="_blank"
              without="true"
              title="Facebook"
              rel="noopener noreferrer"
            >
              <IconSocial src={iconFacebook} alt="logo" />
            </a>
          </Col>
          <Col xs={2} lg={1} className="text-center">
            <a
              href="https://www.instagram.com/saborearresto/"
              target="_blank"
              without="true"
              title="Instagram"
              rel="noopener noreferrer"
            >
              <IconSocial src={iconInstagram} alt="logo" fill="#fff" />
            </a>
          </Col>
          <Col xs={2} lg={1} className="text-center">
            <a
              href="https://wa.me/558896089633"
              target="_blank"
              without="true"
              title="Whatsapp"
              rel="noopener noreferrer"
            >
              <IconSocial src={iconWhats} alt="logo" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6} className="text-center text-white pb-4">
            <TextoDisplay>Horário de Funcionamento</TextoDisplay>
            <TextoDisplay>Self-Service</TextoDisplay>
            <TextoDisplay>Segunda à Sábado</TextoDisplay>
            <TextoDisplay>das 11:00h as 15h</TextoDisplay>
          </Col>
          <Col className="text-center">
            <a href="/">
              <Logo src={logo} alt="logo" />
            </a>
            <p className="mt-1 mb-0">
              <small className="d-inline-block">
                <a
                  href="https://g.page/saborearrestaurante?share"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Google Maps"
                >
                  <span className="d-block">Rua Pascoal Crispino nº285, Centro</span>
                  <span className="d-inline-block">Quixadá - CE</span>
                </a>
              </small>
            </p>
          </Col>
        </Row>

        <Row className="pb-1">
          <Col className="text-center text-white">
            <Texto>
              © COPYRIGHT {new Date().getFullYear()} SABOREAR RESTAURANTE - TODOS OS DIREITOS
              RESERVADOS.
            </Texto>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col className="text-center text-white">
            <a
              href="https://www.linkedin.com/in/cleandersonlobo/"
              target="_blank"
              without="true"
              title="Cleanderson Lobo"
              rel="noopener noreferrer"
            >
              <Texto>DESENVOLVIDO POR CLEANDERSON LOBO</Texto>
            </a>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;

/* eslint-disable prefer-spread */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
import {
  createContext,
  useReducer,
  useEffect,
  useCallback,
  createRef,
  useRef,
  useMemo,
} from 'react';

import { getPlanilhaDelivery } from 'services/api';
import { analytics } from 'config/firebase';
import { whatsappLink } from '../helpers/whatsappLink';
import { isDateMoreOneDay } from '../helpers';
import { verificarIsAberto, generateUid } from '../helpers/utils';

export const KEY_STORAGE = '@saborear/carrinho';
export const KEY_STORAGE_SCROLL_LEFT = '@saborear/scrolLeft';
export const KEY_STORAGE_NOME = '@saborear/nome';
export const KEY_STORAGE_ENDERECO = '@saborear/endereco';
export const KEY_STORAGE_TELEFONE = '@saborear/telefone';
/**
 * 
 * carrinho: {
    total: 13,
    quantidade: 1,
    items: [
      {
        id: new Date().getTime(),
        quantidade: 1,
        total: 13,
        observacao: '',
        selected: PLANILHA.ENTRADAS[0],
      },
    ],
  },
 */
/*
  subtotal: {
    items: []
    total: 0
  }
  entrada: ['entrega', 'retirar']
  pagamento: ['credito', 'debito', 'dinheiro']
  troco se for dinheiro
 */
const initialState = {
  isLoading: false,
  carrinho: {
    id: generateUid(),
    total: 0,
    subtotal: 0,
    quantidade: 0,
    entrega: null,
    selectedBairro: false,
    bairro: null,
    pagamento: null,
    troco: null,
    items: [],
    createAt: new Date(),
    finalizado: null,
  },
  bairros: [],
  selectedItem: null,
  cardapio: null,
  telefone: localStorage.getItem(KEY_STORAGE_TELEFONE) || '',
  endereco: localStorage.getItem(KEY_STORAGE_ENDERECO) || '',
  nome: localStorage.getItem(KEY_STORAGE_NOME) || '',
};

const getInitialState = () => {
  try {
    const carrinhoStorage = localStorage.getItem(KEY_STORAGE);
    if (carrinhoStorage) {
      const carrinho = JSON.parse(carrinhoStorage) || initialState.cardapio;
      const historyIsValid = isDateMoreOneDay(carrinho.createAt);
      if (!carrinho.quantidade || !historyIsValid) {
        localStorage.removeItem(KEY_STORAGE);
        return initialState;
      }
      return {
        ...initialState,
        carrinho: JSON.parse(carrinhoStorage) || initialState.cardapio,
      };
    }
    return initialState;
  } catch (error) {
    return initialState;
  }
};

export const PedidosContext = createContext(getInitialState());

const REDUCERS = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
  ADD_ITEM: 'ADD_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UPDATE_CARRINHO: 'UPDATE_CARRINHO',
  SELECT_ITEM: 'SELECT_ITEM',
  LIMPAR: 'LIMPAR',
  UPDATED: 'UPDATED',
  FINALIZADO: 'FINALIZADO',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.ADD_ITEM:
      const items = [...state.carrinho.items, action.item];
      const total = items.reduce((il, it) => il + it.total, 0);
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          total,
          quantidade: items.length,
          items,
        },
      };
    case REDUCERS.UPDATE_ITEM:
      const { itemId, item } = action;
      const itemsUpdate = state.carrinho.items.map(it => (it.id === +itemId ? item : it));
      const totalUpdate = itemsUpdate.reduce((il, it) => il + it.total, 0);
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          items: itemsUpdate,
          total: totalUpdate,
        },
      };
    case REDUCERS.REMOVE_ITEM:
      const itemsFilter = state.carrinho.items.filter(it => it.id !== +action.itemId);
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          total: itemsFilter.reduce((il, it) => il + it.total, 0),
          quantidade: itemsFilter.length,
          items: itemsFilter,
        },
      };
    case REDUCERS.SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.item,
      };
    case REDUCERS.UPDATE_CARRINHO:
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          ...action,
        },
      };
    case REDUCERS.FETCH_DATA:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case REDUCERS.UPDATED:
      return {
        ...state,
        ...action,
      };
    case REDUCERS.FINALIZADO:
      return {
        ...state,
        carrinho: {
          ...state.carrinho,
          finalizado: true,
          finishAt: new Date(),
        },
      };
    case REDUCERS.FETCH_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        ...action,
      };
    case REDUCERS.FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case REDUCERS.LIMPAR:
      localStorage.removeItem(KEY_STORAGE);
      return {
        ...state,
        carrinho: {
          id: generateUid(),
          ...initialState.carrinho,
        },
      };
    default:
      return state;
  }
};
const useCarrinho = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const refs = useRef({});
  const handleAddItem = item => {
    dispatch({ type: REDUCERS.ADD_ITEM, item });
  };

  const handleSelectItem = item => {
    dispatch({ type: REDUCERS.SELECT_ITEM, item });
  };

  const limparCarrinho = useCallback(() => {
    dispatch({ type: REDUCERS.LIMPAR });
  }, []);
  const removerItem = useCallback(itemId => {
    dispatch({ type: REDUCERS.REMOVE_ITEM, itemId });
  }, []);
  const updateItem = useCallback(actions => {
    dispatch({ type: REDUCERS.UPDATE_ITEM, ...actions });
  }, []);

  const handleOnUpdate = (props = {}) => {
    dispatch({ type: REDUCERS.UPDATE_CARRINHO, ...props });
  };
  const handleUpdateState = (props = {}) => {
    dispatch({ type: REDUCERS.UPDATED, ...props });
  };

  const handleFinalizar = () => {
    const {
      nome,
      endereco,
      carrinho: { entrega, pagamento, finalizado },
    } = state;
    if (!entrega || !pagamento || !nome || (entrega === 'entrega' && !endereco)) return;
    localStorage.removeItem(KEY_STORAGE);
    const params = whatsappLink({
      carrinho: state.carrinho,
      nome: state.nome,
      endereco: state.endereco,
    });

    if (finalizado) {
      analytics.logEvent('pedido_carrinho_finalizar_again');
    } else {
      analytics.logEvent('pedido_carrinho_finalizar_click', {
        tipo_entrega: entrega,
        pagamento,
        total: state.carrinho.total,
        quantidade: state.carrinho.quantidade,
      });
    }

    window.open(`https://api.whatsapp.com/send?phone=558896089633&text=${params}`, '_blank');
    dispatch({ type: REDUCERS.FINALIZADO });
  };

  const carregarPlanilha = useCallback(async () => {
    try {
      dispatch({ type: REDUCERS.FETCH_DATA });
      const { dados, ...restProps } = await getPlanilhaDelivery();

      refs.current = Object.keys(dados).reduce((il, it) => ({ ...il, [it]: createRef() }), {});

      dispatch({
        type: REDUCERS.FETCH_DATA_SUCCESS,
        cardapio: dados,
        isAberto: verificarIsAberto(restProps) || process.env.NODE_ENV === 'development',
        ...restProps,
      });
    } catch (error) {
      dispatch({ type: REDUCERS.FETCH_DATA_FAILURE });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: REDUCERS.FETCH_DATA });
    setTimeout(() => carregarPlanilha(), 300);
    localStorage.removeItem(KEY_STORAGE_SCROLL_LEFT);
  }, []);

  useEffect(() => {
    localStorage.setItem(KEY_STORAGE, JSON.stringify(state.carrinho));
  }, [state.carrinho]);
  useEffect(() => {
    localStorage.setItem(KEY_STORAGE_NOME, state.nome);
  }, [state.nome]);
  useEffect(() => {
    localStorage.setItem(KEY_STORAGE_ENDERECO, state.endereco);
  }, [state.endereco]);

  const cardapioItems = useMemo(() => {
    if (state.cardapio) {
      return [].concat.apply([], Object.values(state.cardapio));
    }
    return [];
  }, [state.cardapio]);
  return {
    cardapioItems,
    handleAddItem,
    handleSelectItem,
    handleOnUpdate,
    limparCarrinho,
    handleUpdateState,
    removerItem,
    handleFinalizar,
    updateItem,
    carregarPlanilha,
    refs,
    ...state,
  };
};

export const CarrinhoProvider = props => {
  const state = useCarrinho();
  return <PedidosContext.Provider value={state}>{props.children}</PedidosContext.Provider>;
};

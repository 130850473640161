/* eslint-disable no-unused-vars */
/* eslint-disable no-async-promise-executor */
import axios from 'axios';

import {
  formatarPlanilha,
  formatarPlanilhaDelivery,
  formatarPlanilhaAcrescimos,
  formatarPlanilhaHorarios,
  generatePlanilhaFromApiV4,
  toObjectFromApiV4,
  formatStringPriceToNumber,
} from 'helpers';

const {
  REACT_APP_PLANILHA,
  REACT_APP_PLANILHA_DELIVERY,
  REACT_APP_PLANILHA_DELIVERY_ACRESCIMOS,
  REACT_APP_PLANILHA_DELIVERY_HORARIOS,
  REACT_APP_PLANILHA_DELIVERY_V4,
  REACT_APP_PLANILHA_DELIVERY_HORARIOS_V4,
  REACT_APP_PLANILHA_DELIVERY_BAIRROS_V4,
} = process.env;

export const getPlanilha = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = formatarPlanilha(data);
      resolve(dados);
    } catch (error) {
      reject(error);
    }
  });

export const getAcrescimos = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_ACRESCIMOS);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = formatarPlanilhaAcrescimos(data);

      resolve(dados);
    } catch (error) {
      reject(error);
    }
  });

export const getHorarios = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_HORARIOS_V4);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = formatarPlanilhaHorarios(data?.values);

      resolve(dados);
    } catch (error) {
      reject(error);
    }
  });

export const getBairros = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_BAIRROS_V4);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = toObjectFromApiV4(data?.values);

      resolve(formatStringPriceToNumber(dados, 'taxa'));
    } catch (error) {
      reject(error);
    }
  });

export const getPlanilhaDelivery = () =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.get(REACT_APP_PLANILHA_DELIVERY_V4);
      if (!resp.data || resp.status > 201) throw resp;
      const { data } = resp;

      const dados = formatarPlanilhaDelivery(data?.values);

      const horarios = await getHorarios();
      const bairros = await getBairros();

      resolve({ dados, horarios, bairros });
    } catch (error) {
      reject(error);
    }
  });

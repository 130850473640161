import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { SectionTitle } from 'components';
import logo from 'assets/logo.svg';
import plateImg from 'assets/img/plate-min.png';
import { Header, Background, Logo, Title, Sublogo, Link, Section, Button, Texto } from './styles';

const PageFound = () => {
  return (
    <>
      <Helmet>
        <title>Página não encontrada - Saborear Restaurante</title>
      </Helmet>
      <main className="pb-5">
        <Header>
          <Background />
          <Container className="justify-content-center align-items-center">
            <Row className="justify-content-center align-items-center pb-lg-5 pb-2">
              <Col xs={12} className="text-center">
                <a href="/">
                  <Logo src={logo} alt="logo" />
                </a>
                <Link
                  href="https://g.page/saborearrestaurante?share"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Google Maps"
                >
                  <Sublogo>Rua Pascoal Crispino nº285, Centro</Sublogo>
                  <Sublogo>Quixadá - CE</Sublogo>
                </Link>
              </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
              <Col lg={6} className="text-center">
                <Title>PÁGINA NÃO ENCONTRADA</Title>
              </Col>
            </Row>
          </Container>
        </Header>
        <Section id="404">
          <Container>
            <SectionTitle title="404" />
            <hr />
            <Row className="justify-content-center align-items-center">
              <Col lg={6} className="text-center justify-content-center">
                <Texto>Não achou a página que estava procurando?</Texto>
                <a href="/">
                  <Texto>Volte para página inicial.</Texto>
                </a>
                <Button href="/pedido">FAZER PEDIDO</Button>
              </Col>
              <Col lg={6} className="text-center">
                <a href="/pedido">
                  <img src={plateImg} alt="Prato de comida" className="img-responsive" />
                </a>
              </Col>
            </Row>
          </Container>
        </Section>
      </main>
    </>
  );
};

PageFound.propTypes = {};

export default PageFound;
